import { FunctionComponent, useState } from "react";
import Logo from '../../assets/logo.png';

interface NavbarProps {}

const Navbar: FunctionComponent<NavbarProps> = () => {

  return (
    <div className="navbar-container">
        <div className="navbar-brand">
            <img className="logo" src={Logo} alt="Liquidity Locker" />
            <div className="brand-name">Liquidity Locker</div>
        </div>
        <div className="nav-menu__container">
            <span className="nav-menu__container">Home</span>
            <span className="nav-menu__container">Liquidity Locker</span>
            <span className="nav-menu__container">Token Vesting</span>
        </div>


      {/* <h1>Navbar works</h1> */}
    </div>
  );
};

export default Navbar;
