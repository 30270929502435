import { FunctionComponent } from "react";
import LiquiditySection from "../components/LiquiditySection";
import Navbar from "../components/Navbar";
import TopSection from "../components/TopSectoin";

interface MainProps {
    
}
 
const Main: FunctionComponent<MainProps> = () => {
    return ( 
        <div>
            <Navbar />
            <TopSection /> 
            <LiquiditySection />
        </div>
     );
}
 
export default Main;