import { FunctionComponent } from "react";

interface TopSectionProps {}

const TopSection: FunctionComponent<TopSectionProps> = () => {
  return (
    <div className="top-section_container">
      <div className="top-section_text">
        Smart Liquidity Locker and Token Vesting For Ethereum, Polygon and BSC
        <button className="locker-btn top-section_btn">Get Started</button>
      </div>
    </div>
  );
};

export default TopSection;
