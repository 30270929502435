import { FunctionComponent } from "react";

interface LiquiditySectionProps {
    
}
 
const LiquiditySection: FunctionComponent<LiquiditySectionProps> = () => {
    return ( 
        <div className="liquidty-section_container">
            <div className="section_title">Liquidity Locker</div>
            <div className="section_text mt-24">Lock liquidity pool tokens. Supports Ethereum (Uniswap V2), Polygon(Quickswap), BSC(PancakeSwap)</div>
            <div className="connect-btn">
            <button className="locker-btn">Connect Wallet</button>
            </div>
            <div className="section_text mt-22">Could not Connect. Please check your Wallet network.</div>
        </div>
     );
}
 
export default LiquiditySection;